import React from 'react';

import { Icon, IconProps } from '../base-icon';

export const H2Icon = (props: IconProps) => (
  <Icon name='h2 icon' viewBox='0 0 634 510' {...props}>
    <path
      d='M556.025 293.643c23.047 0 40.796 5.322 53.247 15.966 12.452 10.645 18.677 25.928 18.677 45.85 0 8.398-1.562 16.577-4.687 24.536-3.125 7.96-7.984 16.309-14.575 25.049-6.592 8.74-17.212 20.434-31.86 35.083l-27.54 31.787h83.936V510H484.395v-32.227l68.554-72.07c16.895-19.238 25.342-34.521 25.342-45.85 0-9.18-2.002-16.162-6.006-20.947-4.004-4.785-9.814-7.177-17.431-7.177-7.52 0-13.624 3.198-18.311 9.594-4.688 6.397-7.031 14.38-7.031 23.95H480c0-13.086 3.271-25.17 9.814-36.254 6.543-11.084 15.625-19.751 27.247-26.001 11.62-6.25 24.609-9.375 38.964-9.375zM176 0c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16h-32v128h192V64h-32c-8.837 0-16-7.163-16-16V16c0-8.837 7.163-16 16-16h160c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16h-32v320h32c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16H304c-8.837 0-16-7.163-16-16v-32c0-8.837 7.163-16 16-16h32V256H144v128h32c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16H16c-8.837 0-16-7.163-16-16v-32c0-8.837 7.163-16 16-16h32V64H16C7.163 64 0 56.837 0 48V16C0 7.163 7.163 0 16 0h160z'
      fill='currentColor'
      fillRule='nonzero'
    />
  </Icon>
);
