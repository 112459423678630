export default {
  satisfied: 'laughing',
  grinning_face_with_star_eyes: 'star_struck',
  face_with_one_eyebrow_raised: 'raised_eyebrow',
  telephone: 'phone',
  cooking: 'fried_egg',
  'flag-cn': 'cn',
  lantern: 'izakaya_lantern',
  shocked_face_with_exploding_head: 'exploding_head',
  open_book: 'books',
  'flag-de': 'de',
  grinning_face_with_one_large_and_one_small_eye: 'zany',
  serious_face_with_symbols_covering_mouth: 'symbols_over_mouth',
  flipper: 'dolphin',
  face_with_open_mouth_vomiting: 'vomiting',
  'flag-es': 'es',
  face_with_finger_covering_closed_lips: 'shushing',
  smiling_face_with_smiling_eyes_and_hand_covering_mouth: 'hand_over_mouth',
  'flag-fr': 'fr',
  bee: 'honeybee',
  envelope: 'email',
  gb: 'uk',
  'flag-gb': 'uk',
  'flag-uk': 'uk',
  shit: 'poop',
  hankey: 'poop',
  knife: 'hocho',
  pencil: 'memo',
  'flag-it': 'it',
  'flag-jp': 'jp',
  heavy_exclamation_mark: 'exclamation',
  'flag-kr': 'kr',
  mother_christmas: 'mrs_claus',
  tornado_cloud: 'tornado',
  'flag-ru': 'ru',
  'flag-us': 'us',
  man_and_woman_holding_hands: 'couple',
  hand_with_index_and_middle_fingers_crossed: 'crossed_fingers',
  hand: 'raised_hand',
  thumbsup: '+1',
  thumbsdown: '-1',
  punch: 'facepunch',
  collision: 'boom',
  shirt: 'tshirt',
  shoe: 'mans_shoe',
} as const;
