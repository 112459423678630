import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/introduction"
        }}>{`Introduction`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/install"
        }}>{`Installation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/guides"
        }}>{`Guides`}</a><ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/guides/quickstart"
            }}>{`Quick start`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/guides/controlled"
            }}>{`Controlled`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/guides/advanced-guide"
            }}>{`Advanced guide`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/guides/custom-keys"
            }}>{`Custom Keys`}</a></li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/showcase"
        }}>{`Showcase`}</a><ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/showcase/social"
            }}>{`Social editor`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/showcase/wysiwyg"
            }}>{`Wysiwyg editor`}</a></li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/terms"
        }}>{`Terms`}</a><ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/terms/extension"
            }}>{`Extension`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/terms/remirror-manager"
            }}>{`Remirror manager`}</a></li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/contributing"
        }}>{`Contributing`}</a><ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/contributing/#code-of-conduct"
            }}>{`Code of conduct`}</a></li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      