/*
 * Based on Plugin: Syntax Highlighter CB
 * Plugin URI: http://wp.tutsplus.com/tutorials/plugins/adding-a-syntax-highlighter-shortcode-using-prism-js
 * Description: Highlight your code snippets with an easy to use shortcode based on Lea Verou's Prism.js.
 * Version: 1.0.0
 * Author: c.bavota
 * Author URI: http://bavotasan.comhttp://wp.tutsplus.com/tutorials/plugins/adding-a-syntax-highlighter-shortcode-using-prism-js/ */
/* http://cbavota.bitbucket.org/syntax-highlighter/  */

import { css } from '@remirror/react-utils';

export default css`
  code[class*='language-'],
  pre[class*='language-'] {
    color: #fff;
    text-shadow: 0 1px 1px #000;
    font-family: Menlo, Monaco, 'Courier New', monospace;
    direction: ltr;
    text-align: left;
    word-spacing: normal;
    white-space: pre;
    word-wrap: normal;
    line-height: 1.4;
    background: none;
    border: 0;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }

  pre[class*='language-'] code {
    float: left;
    padding: 0 15px 0 0;
  }

  pre[class*='language-'],
  :not(pre) > code[class*='language-'] {
    background: #222;
  }

  /* Code blocks */
  pre[class*='language-'] {
    padding: 15px;
    margin: 1em 0;
    overflow: auto;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
  }

  /* Inline code */
  :not(pre) > code[class*='language-'] {
    padding: 5px 10px;
    line-height: 1;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #797979;
  }

  .token.selector,
  .token.operator,
  .token.punctuation,
  .token.punctuation.important {
    color: #fff;
  }

  .token.namespace {
    opacity: 0.7;
  }

  .token.tag,
  .token.boolean {
    color: #ffd893;
  }

  .token.atrule,
  .token.attr-value,
  .token.hex,
  .token.string {
    color: #b0c975;
  }

  .token.property,
  .token.entity,
  .token.url,
  .token.attr-name,
  .token.keyword {
    color: #c27628;
  }

  .token.regex {
    color: #9b71c6;
  }

  .token.entity {
    cursor: help;
  }

  .token.function,
  .token.constant {
    color: #e5a638;
  }

  .token.variable {
    color: #fdfba8;
  }

  .token.number {
    color: #8799b0;
  }

  .token.important,
  .token.deliminator {
    color: #e45734;
  }

  /* Line highlight plugin */
  pre[data-line] {
    position: relative;
    padding: 1em 0 1em 3em;
  }

  .line-highlight {
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 1em; /* Same as .prism's padding-top */
    background: rgba(255, 255, 255, 0.2);
    pointer-events: none;
    line-height: inherit;
    white-space: pre;
  }

  .line-highlight:before,
  .line-highlight[data-end]:after {
    content: attr(data-start);
    position: absolute;
    top: 0.3em;
    left: 0.6em;
    min-width: 1em;
    padding: 0 0.5em;
    background-color: rgba(255, 255, 255, 0.3);
    color: #fff;
    font: bold 65%/1.5 sans-serif;
    text-align: center;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    text-shadow: none;
  }

  .line-highlight[data-end]:after {
    content: attr(data-end);
    top: auto;
    bottom: 0.4em;
  }

  /* for line numbers */
  .line-numbers-rows {
    margin: 0;
  }

  .line-numbers-rows span {
    padding-right: 10px;
    border-right: 3px #d9d336 solid;
  }
`;
